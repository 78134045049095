exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-culture-cultural-significance-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/culture/cultural-significance/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-culture-cultural-significance-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-fashion-cyberpunk-and-traditional-navratri-attire-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/fashion/cyberpunk-and-traditional-navratri-attire/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-fashion-cyberpunk-and-traditional-navratri-attire-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-fashion-traditional-navratri-attire-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/fashion/traditional-navratri-attire/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-fashion-traditional-navratri-attire-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-festival-the-evolution-of-garba-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/festival/the-evolution-of-garba/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-festival-the-evolution-of-garba-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-food-fasting-during-navratri-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/food/fasting-during-navratri/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-food-fasting-during-navratri-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-garba-inner-circle-garba-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/garba/inner-circle-garba/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-garba-inner-circle-garba-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-garba-mandli-garba-og-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/garba/mandli-garba-og/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-garba-mandli-garba-og-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-garba-the-sheri-affair-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/matp/Library/Mobile Documents/com~apple~CloudDocs/Desktop/ICiTified/NavratriGarba/code/current/@ng:front/content/garba/the-sheri-affair/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-garba-the-sheri-affair-post-mdx" */)
}

